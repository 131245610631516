import { Box, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { tableTextStyles } from 'utils/constants/app';

export const LinkTypography = ({ data, value, backToUrl, goToUrl }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    console.log(data);
    navigate(goToUrl, {
      state: { data, backToUrl },
    });
  };

  return (
    <Box>
      <Typography
        onClick={handleClick}
        sx={
          (tableTextStyles,
          {
            textDecoration: 'underline',
            color: 'general.link',
            fontWeight: 400,
            '&:hover': {
              cursor: 'pointer',
            },
          })
        }
      >
        {value}
      </Typography>
    </Box>
  );
};
