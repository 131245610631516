import { DEFAULT_LANGUAGE } from 'utils/constants/app';
import { LS_LANGUAGE } from 'utils/constants/localStorage';

export const noop = () => {};

export const paginationCounter = (total = 0, noOfPageItems = 30) => {
  return Math.ceil(total / noOfPageItems);
};

export const getLanguageCode = () => {
  return localStorage.getItem(LS_LANGUAGE) || DEFAULT_LANGUAGE;
};

export const generateRandomColor = () => {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);
  return '#' + randomColor;
};

export const generateNRandomColor = (num = 1) => {
  const colors = [];
  for (let i = 0; i < num; i++) {
    const color = generateRandomColor();
    colors.push(color);
  }

  return colors;
};
