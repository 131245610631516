import React, { StrictMode, Suspense } from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HelmetProvider } from 'react-helmet-async';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from 'contexts/ThemeProvider';
import { CssBaseline } from '@mui/material';

// Import components and providers
import MainLoader from 'components/common/MainLoader';
import AppProvider from 'contexts/AppProvider';
import UserProvider from 'contexts/UserProvider';
import { SnackbarClose } from 'sections/commons';

// Import styles
import '@fontsource/righteous';
import '@fontsource/poppins/100.css';
import '@fontsource/poppins/200.css';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/800.css';
import '@fontsource/raleway/100.css';
import '@fontsource/raleway/200.css';
import '@fontsource/raleway/300.css';
import '@fontsource/raleway/400.css';
import '@fontsource/raleway/500.css';
import '@fontsource/raleway/600.css';
import '@fontsource/raleway/700.css';
import '@fontsource/raleway/800.css';
import '@fontsource/raleway/900.css';
import 'translations';
import 'index.css';

import { theme } from 'utils/theme';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

const App = React.lazy(() => import('./App'));

const root = document.getElementById('root');

if (!root) {
  throw new Error('Root element not found in the DOM.');
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: 30000,
      refetchOnWindowFocus: true,
      suspense: true,
    },
  },
});

render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <SnackbarProvider
              autoHideDuration={3000}
              maxSnack={3}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              preventDuplicate
              action={snackbarKey => (
                <SnackbarClose snackbarKey={snackbarKey} />
              )}
            >
              <AppProvider>
                <UserProvider>
                  <CssBaseline />
                  <Suspense fallback={<MainLoader />}>
                    <App />
                  </Suspense>
                </UserProvider>
              </AppProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </BrowserRouter>
      </HelmetProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </StrictMode>,
  root
);

serviceWorker.register();

reportWebVitals();
