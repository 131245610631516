import { Button, Stack } from '@mui/material';
import React from 'react';
import { noop } from 'utils/helpers';

export const AcceptCancelButton = ({
  acceptLabel = 'Accept',
  cancelLabel = 'Deny',
  variantAccept = 'contained',
  variantCancel = 'outlined',
  cancelColor = 'error',
  acceptColor = 'primary',
  onAccept = noop,
  onDeny = noop,
  justifyContent = 'space-around',
  sx,
}) => {
  return (
    <Stack direction="row" justifyContent={justifyContent}>
      <Button
        variant={variantCancel}
        color={cancelColor}
        onClick={onDeny}
        sx={{ ...sx }}
      >
        {cancelLabel}
      </Button>
      <Button
        variant={variantAccept}
        color={acceptColor}
        onClick={onAccept}
        sx={{ ...sx }}
      >
        {acceptLabel}
      </Button>
    </Stack>
  );
};
