import React from 'react';
import { Typography, Stack, IconButton } from '@mui/material';

export const IconWithText = ({
  icon,
  text,
  color = 'primary',
  textColor = 'text.main',
}) => (
  <Stack direction="row" spacing={1} alignItems="center">
    <IconButton
      color={color}
      disableHoverRipple
      disableRipple
      disableTouchRipple
    >
      {icon}
    </IconButton>
    <Typography variant="body1" sx={{ color: textColor }}>
      {text}
    </Typography>
  </Stack>
);
