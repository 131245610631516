import { useSnackbar } from 'notistack';
import { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { noop } from 'utils';
import {
  loginUserApi,
  signupUserApi,
  getProfileApi,
  getUserSettingsApi,
  updateUserSettingsApi,
  updateProfileApi,
  forgotUserPasswordApi,
  resetUserPasswordApi,
} from 'utils/constants/apis';
import {
  APP_NAME,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LANGUAGE,
} from 'utils/constants/app';
import {
  LS_LANGUAGE,
  LS_TIMEZONE,
  LS_USER_ID,
  LS_USER_STUDENT_ID,
} from 'utils/constants/localStorage';
import { DEFAULT_TIMEZONE } from 'utils/constants/settings';
import axiosInstance from 'utils/helpers/axios';
import { updateStudentStatus } from 'utils/helpers/student';

const UserContext = createContext({
  loading: false,
  auth: null,
  handleLogin: noop,
  clearSession: noop,
  getSettings: noop,
  profileData: null,
  getUserProfile: noop,
  updateLeaveDeleteForAWhile: noop,
  updateSettings: noop,
  editStudentProfile: noop,
  handleForgotPassword: noop,
  handleResetPassword: noop,
});

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const userId = localStorage.getItem(LS_USER_ID) || null;
  const studentId = localStorage.getItem(LS_USER_STUDENT_ID) || null;
  const defaultAuth =
    userId && studentId
      ? {
          userId,
          studentId,
        }
      : null;
  const [auth, setAuth] = useState(defaultAuth);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('app');
  const [profileData, setProfileData] = useState({});
  console.log(auth);
  const [timezone, setTimezone] = useState(DEFAULT_TIMEZONE);
  const [language, setLanguage] = useState(DEFAULT_LANGUAGE);

  const handleLogin = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);

    axiosInstance
      .post(loginUserApi(), { ...payload, appName: APP_NAME })
      .then(data => {
        enqueueSnackbar(t('Login successful'), {
          variant: 'success',
        });
        // console.log(data);
        const { id: userId, student } = data;
        const { id: studentId } = student;
        if (userId) localStorage.setItem(LS_USER_ID, userId);
        if (studentId) localStorage.setItem(LS_USER_STUDENT_ID, studentId);
        let status = updateStudentStatus(data);
        const auth = {
          userId,
          studentId,
        };
        setAuth(auth);
        setStatus(status);
        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });
        clearSession();
        onError(err);
      })
      .finally(() => setLoading(false));
  };

  const handleSignup = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    axiosInstance
      .post(signupUserApi(), { ...payload, appName: APP_NAME })
      .then(data => {
        enqueueSnackbar(t('Registered Successfully'), {
          variant: 'success',
        });
        const { id: userId, student } = data;
        const { id: studentId } = student;
        if (userId) localStorage.setItem(LS_USER_ID, userId);
        if (studentId) localStorage.setItem(LS_USER_STUDENT_ID, studentId);
        const auth = {
          userId,
          studentId,
        };

        let status = updateStudentStatus(data);
        setAuth(auth);
        setStatus(status);
        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });

        // onError(err);
      })
      .finally(() => setLoading(false));
  };

  const clearSession = () => {
    localStorage.removeItem(LS_USER_ID);
    localStorage.removeItem(LS_USER_STUDENT_ID);
    localStorage.clear();
    setAuth(null);
  };

  const getUserProfile = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    const { studentId } = auth;

    axiosInstance
      .get(getProfileApi(studentId))
      .then(res => {
        const educationDetails = [
          {
            id: 0,
            schoolName: 'ABC School',
            facultyName: 'XYZ',
            departmentName: 'PQERT',
            year: 2013,
            month: 3,
            state: 'Delhi',
          },
        ];
        setProfileData({ ...res, educationDetails });
        onSuccess(res);
        console.log(profileData);
      })
      .catch(err => {
        // onError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editStudentProfile = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    const { studentId } = auth;
    axiosInstance
      .patch(updateProfileApi(studentId), payload)
      .then(data => {
        setProfileData(data);
        onSuccess(data);
      })
      .catch(err => {
        console.log(err);
        enqueueSnackbar(t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });
        onError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getSettings = (onSuccess = noop, onError = noop) => {
    setLoading(true);
    const userId = auth?.userId;

    axiosInstance
      .get(getUserSettingsApi(userId))
      .then(data => {
        const { timezone, language } = data;
        setTimezone(timezone);
        setLanguage(language);
        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });
        // onError(err);
      })
      .finally(() => setLoading(false));
  };

  const updateSettings = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    axiosInstance
      .put(updateUserSettingsApi(), payload)
      .then(data => {
        enqueueSnackbar(t('Updated settings successfully'), {
          variant: 'success',
        });
        const { timezone, language } = data;
        setTimezone(timezone);
        setLanguage(language);
        localStorage.setItem(LS_LANGUAGE, language);
        localStorage.setItem(LS_TIMEZONE, timezone);
        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });
        // onError(err);
      })
      .finally(() => setLoading(false));
  };

  const updateLeaveDeleteForAWhile = (
    payload,
    onSuccess = noop,
    onError = noop
  ) => {
    setLoading(true);
    axiosInstance
      .post(updateUserSettingsApi(), payload)
      .then(data => {
        enqueueSnackbar(t('Updated settings successfully'), {
          variant: 'success',
        });
        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });
        onError(err);
      })
      .finally(() => setLoading(false));
  };

  // Anonymous Forgot Password
  const handleForgotPassword = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    axiosInstance
      .post(forgotUserPasswordApi(), { ...payload })
      .then(data => {
        enqueueSnackbar(t('Password reset link has been sent to your email'), {
          variant: 'success',
        });
        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });

        onError(err);
      })
      .finally(() => setLoading(false));
  };

  // Anonymous Reset Password
  const handleResetPassword = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    const { token, ...rest } = payload;
    axiosInstance
      .post(resetUserPasswordApi(token), { ...rest })
      .then(data => {
        enqueueSnackbar(t('Password changed successfully. Please login.'), {
          variant: 'success',
        });
        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });

        onError(err);
      })
      .finally(() => setLoading(false));
  };

  const contextValue = {
    loading,
    auth,
    profileData,
    handleLogin,
    handleSignup,
    clearSession,
    getUserProfile,
    getSettings,
    updateSettings,
    updateLeaveDeleteForAWhile,
    editStudentProfile,
    handleForgotPassword,
    handleResetPassword,
  };

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
