import { Avatar, Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LOGO } from 'utils/constants/images';

const MainLoader = ({
  message = 'Loading application',
  backgroundColor = 'primary',
}) => {
  const { t } = useTranslation('app');
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        backgroundColor: { backgroundColor },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Avatar
        src={LOGO}
        variant="square"
        sx={{
          width: '4rem',
          height: 'auto',
          my: 4,
        }}
      />
      <Typography variant="h5">{t(message)}...</Typography>
    </Box>
  );
};

export default MainLoader;
