export const DEFAULT_TIMEZONE = 'JST';

export const TIME_ZONE = [
  {
    text: 'Japan (GMT +9) Tokyo',
    value: 'JST',
  },
];

export const LEAVE_QUESTIONS = [
  {
    id: 1,
    value: 'Going abroad or working holiday',
  },
  {
    id: 2,
    value: 'The application is difficult to use and the service is poor.',
  },
  {
    id: 3,
    value: 'There are few companies listed in the application',
  },
];

export const DELETE_QUESTIONS = [
  {
    id: 1,
    value: 'Going to graduate school or other schools',
  },
  {
    id: 2,
    value: 'Going abroad or working holiday',
  },
  {
    id: 3,
    value: 'The application is difficult to use and the service is poor.',
  },
  {
    id: 4,
    value: ' There are few companies listed in the application',
  },
];
