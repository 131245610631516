export const palette = {
  mode: 'light',
  primary: {
    lighter: '#F5F4FF',
    light: '#F5F4FF',
    main: '#0B52BD',
  },
  secondary: {
    bgLight: '#E6FFEC',
    light: '#938CBF',
    main: '#F44336',
  },
  success: {
    light: '#78e08f',
    main: '#17AF38',
    dark: '#44bd32',
    contrastText: '#fff',
  },
  info: {
    light: '#1B9CFC',
    main: '#00a8ff',
    dark: '#0097e6',
  },
  warning: {
    lighter: '#ffd32a',
    light: '#ff9800',
    main: '#ed6c02',
    dark: '#e65100',
  },
  error: {
    light: '#ff5e57',
    main: '#ff3f34',
    dark: '#EA2027',
  },
  general: {
    light: '#F2EFFF',
    link: '#0B52BD',
  },
  action: {
    selected: '#FF8600',
    main: '#FF8600',
    // disabledBackground: '#BFBFBF',
    // disabled: 'white',
  },
  expired: {
    light: '#BFBFBF',
  },
};

export const expiredcolor = {
  main: '#BFBFBF',
};

export const background = {
  paper: '#FFFFFF',
  default: '#f5f6fa',
  light: '#EAE6FF',
};

export const border = {
  default: '#EAEAEA',
};

const fontWeightLight = 400;
const fontWeightRegular = 500;
const fontWeightMedium = 700;
const fontWeightBold = 900;

const shadows = [
  'none',
  'rgba(71, 88, 114, 0.1) 0px 2px 6px',
  'rgb(221 230 237 / 40%) 0px 16px 32px 0px',
  '0 6px 20px 0 rgba(26,24,30,.16)',
  '0 6px 20px 0 rgba(26,24,30,.16)',
  '0 6px 20px 0 rgba(26,24,30,.16)',
  '0 6px 20px 0 rgba(26,24,30,.16)',
  '0 6px 20px 0 rgba(26,24,30,.16)',
  '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
];

let themeObj = {
  shadows: shadows,
  palette,
  spacing: factor => `${0.25 * factor}rem`,
  typography: {
    htmlFontSize: 25,
    fontFamily: `'Poppins', sans-serif`,
    fontWeightLight,
    fontWeightRegular,
    fontWeightMedium,
    fontWeightBold,
    h1: {},
    h2: {},
    h3: {
      // fontSize: '2rem',
      // fontWeight: fontWeightBold,
    },
    h4: {
      // fontSize: '1.5rem',
      // fontWeight: fontWeightMedium,
    },
    h5: {
      // fontSize: '1.25rem',
      // fontWeight: fontWeightMedium,
    },
    h6: {
      // fontSize: '1rem',
      // fontWeight: fontWeightMedium,
    },
    body1: {
      // fontWeight: fontWeightRegular,
    },
    body2: {},
    button: {
      // fontWeight: fontWeightRegular,
      textTransform: 'initial',
    },
  },
  components: {
    MuiCard: {
      defaultProps: {
        elevation: 0,
        variant: 'outlined',
      },
    },

    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            padding: 16,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          // borderRadius: theme.spacing(4),
        }),
      },
      defaultProps: {
        elevation: 1,
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'link' },
          style: {
            textDecoration: 'underline',
            cursor: 'pointer',
            color: theme => theme.palette.info.main,
          },
        },
      ],
    },
    MuiListItemButton: {
      styleOverrides: {
        root: theme => ({
          '&.Mui-selected': {
            borderRadius: 3,
          },
        }),
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          // fontSize: '0.7rem',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
        disableElevation: true,
        disableFocusRipple: true,
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.square': {
            borderRadius: theme.spacing(4),
          },
        }),
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          display: 'inline-flex',
          flexWrap: 'wrap',
        },
        grouped: ({ theme }) => ({
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(1),
        }),
        groupedContained: ({ theme }) => ({
          marginRight: `${theme.spacing(4)} !important`,
          borderRadius: `${theme.spacing(8)} !important`,
          border: '0 !important',
        }),
        groupedTextHorizontal: {},
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: ({ theme }) => ({
          // borderRadius: theme.spacing(4),
          boxShadow: theme.shadows[3],
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          paddingRight: 2,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: palette.error.dark,
          // fontSize: '1.5rem',
        },
      },
    },
    MuiPickerStaticWrapper: {
      styleOverrides: {
        root: {
          '>div:first-child>div:first-child': {
            display: 'none',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          paddingTop: '0.54rem !important',
          paddingBottom: '0.54rem !important',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 'bold',
        },
      },
    },
  },
};

export const theme = themeObj;
