export const HOME = '/student';

//Internships
export const INTERNSHIP = '/student/internship';
export const INTERNSHIP_SEARCH = `${INTERNSHIP}/public`;
export const INTERNSHIP_POSTED_BY_COMPANY = `${INTERNSHIP_SEARCH}/all`;
export const INTERNSHIP_APPLICATION = `${INTERNSHIP}/university`;
export const INTERNSHIP_INTERVIEW = `${INTERNSHIP}/interview`;
export const INTERNSHIP_INTERVIEW_BY_UNIVERITY = `${INTERNSHIP_INTERVIEW}`;
export const INTERNSHIP_INTERVIEW__BY_COMPANY = `${INTERNSHIP_INTERVIEW}?tab=1`;
export const INTERNSHIP_INTERVIEW_OFFER_LETTER = `${INTERNSHIP_INTERVIEW}?tab=2`;
export const INTERNSHIP_INTERVIEW_FROM_UNIVERSITY = `${INTERNSHIP_INTERVIEW}?tab=0`;
export const INTERNSHIP_INTERVIEW_FROM_COMPANY = `${INTERNSHIP_INTERVIEW}?tab=1`;
export const INTERNSHIP_INTERVIEW_ACCEPTED = `${INTERNSHIP_INTERVIEW}?tab=2`;
export const INTERNSHIP_OFFER_LETTER = `${INTERNSHIP_INTERVIEW}?tab=3`;
export const INTERNSHIP_ACCEPTED = `${INTERNSHIP}/accepted`;
export const MY_INTERNSHIP = `${HOME}/my-internships`;
export const MY_INTERNSHIP_FEEDBACK = `${INTERNSHIP_ACCEPTED}/feedback`;
export const INTERNSHIP_SEARCH_COMPANY_DETAILS = `${INTERNSHIP_SEARCH}/company-details`;
export const INTERNSHIP_APPLICATION_COMPANY_DETAILS = `${INTERNSHIP_APPLICATION}/company-details`;
export const INTERNSHIP_INTERVIEW_COMPANY_DETAILS = `${INTERNSHIP_INTERVIEW}/company-details`;
export const INTERNSHIP_ACCEPTED_COMPANY_DETAILS = `${INTERNSHIP_ACCEPTED}/company-details`;

export const HELP_AND_FEEDBACK = `${HOME}/held-and-feedback`;
export const HELP_CREATE_ACCOUNT = `${HELP_AND_FEEDBACK}/create-an-account`;

export const DASHBOARD = '/student/dashboard';

export const FAVORITES = '/student/favorites';
export const FAVORITES_COMPANY_DETALS = `${FAVORITES}/company-details`;
export const FAVORITES_INTERNSHIP_DETALS = `${FAVORITES}/internship-details`;
export const NOTIFICATION = '/student/notification';
export const PROFILE = '/student/profile';
export const EDIT_PROFILE = '/student/profile/edit';
export const SETTINGS = '/student/settings';

//APP
export const RESET_PASSWORD_FUNC = (token = ':token') =>
  '/app/reset-password/' + token;
export const APP = '/app';
export const LOGIN = '/app/login';
export const REGISTER = '/app/register';
export const CREATE_PROFILE = '/app/create-profile';
export const FORGOT_PASSWORD = '/app/forgot-password';
export const RESET_PASSWORD = '/app/reset-password';
export const CREATE_PASSWORD = '/app/create-password';

export const SIGNOUT = '/student/signout';

export const STUDENT_RESUME_PREVIEW = '/student/profile/resume/preview';
