export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const AUTH_API_ENDPOINT = process.env.REACT_APP_AUTH_API_ENDPOINT;
export const APPPLIED_API_ENDPOINT =
  process.env.REACT_APP_APPPLIED_API_ENDPOINT;
export const YOUNG_BRAIN_API_ENDPOINT =
  process.env.REACT_APP_YOUNG_BRAIN_API_ENDPOINT;
export const youngbrainBaseEndpoint = api => YOUNG_BRAIN_API_ENDPOINT + api;
export const accountBaseEndpoint = api => API_ENDPOINT + 'account/v1' + api;
export const authBaseEndpoint = api => API_ENDPOINT + 'internship/v1/' + api;

export const baseEndpoint = api => API_ENDPOINT + 'internship/v1/' + api;
export const filterEndpoint = api =>
  API_ENDPOINT + 'internship/filter/v1/' + api;
export const appliedBaseEndpoint = api =>
  API_ENDPOINT + 'applied/internship/v1/' + api;
export const univeristyBaseEndpoint = api =>
  API_ENDPOINT + 'university/internship/v1/' + api;
export const univeristyAppliedBaseEndpoint = api =>
  API_ENDPOINT + 'internship-applications/v1/' + api;
export const interviewBaseEndpoint = api =>
  API_ENDPOINT + 'interview/v1/' + api;
export const notificationBaseEndpoint = api =>
  API_ENDPOINT + 'v1/notification/' + api;

// Dashboard
export const getDashboardInternshipByAreaApi = (path = '') =>
  baseEndpoint('dashboard/internshipByAreaMonthwise' + path);

export const getDashboardAllInternshipsMonthWiseApi = (path = '') =>
  baseEndpoint('dashboard/allInternshipsByMonth' + path);

// Internships
export const getInternshipsApi = (params = '') =>
  baseEndpoint('findall') + params;
export const applyPublicInternshipApi = (params = '') =>
  baseEndpoint('apply') + params;
export const applyUniversityInternshipApi = (params = '') =>
  univeristyBaseEndpoint('apply') + params;
export const saveFavouriteUniversityInternshipApi = (params = '') =>
  univeristyBaseEndpoint('setFavourite') + params;
export const denyAcceptedInterview = (params = '') =>
  baseEndpoint('setFavourite') + params;

export const getAcceptedInternshipsApi = (params = '') =>
  baseEndpoint('accepted/list') + params;
export const getCancelledInternshipsApi = (params = '') =>
  baseEndpoint('cancelled/list') + params;

export const cancelInternshipApi = (params = '') =>
  baseEndpoint('cancel/') + params;

export const getCompletedInternshipsApi = (params = '') =>
  baseEndpoint('completed/list') + params;

export const getFavouriteInternshipsApi = (params = '') =>
  baseEndpoint('favourites/list') + params;

export const getFavouriteUniversityInternshipsApi = (params = '') =>
  univeristyBaseEndpoint('favourites/list') + params;

export const getAppliedInternshipsApi = (params = '') =>
  baseEndpoint('findAllAppliedInternship') + params;

export const getInternshipTitlesApi = () =>
  baseEndpoint('internship-title/list');

export const getInternshipStylesApi = () =>
  filterEndpoint('internship-style/list');
export const getInternshipLocationsApi = () => filterEndpoint('location/list');
export const getIndustryFiltersApi = () => filterEndpoint('industry/list');
export const getInternshipIndustriesApi = () => filterEndpoint('industry/list');
export const getInternshipCompensationApi = () =>
  filterEndpoint('compensation/expenses/list');
export const getApplicationRequirementApi = () =>
  filterEndpoint('application/requirements/list');
export const getPeriodApi = () => filterEndpoint('period/list');
export const getScienceHumanitiesApi = () =>
  filterEndpoint('application/requirements/science-humanities/list');
export const getSelectionProcessApi = () =>
  filterEndpoint('selection/process/list');
export const getOthersOptionsApi = () => filterEndpoint('others/list');
export const getFilteredDataApi = (params = '') =>
  baseEndpoint('findall') + params;
export const setFavouriteInternshipApi = (params = '') =>
  baseEndpoint('setFavourite') + params;

export const getInternshipNotificationApi = (params = '') =>
  notificationBaseEndpoint('internship') + params;

export const getOldInternshipNotificationApi = (params = '') =>
  notificationBaseEndpoint('internship') + params;

export const deleteInternshipNotificationApi = (params = '') =>
  notificationBaseEndpoint('internship') + params;

export const deleteOldInternshipNotificationApi = (params = '') =>
  notificationBaseEndpoint('internship') + params;

//University Internships
export const getUniversityPostedInternshipsApi = (params = '') =>
  univeristyBaseEndpoint('findall') + params;

export const getUniversityAppliedInternshipsApi = (params = '') =>
  univeristyBaseEndpoint('findAllAppliedInternship') + params;

export const getInterviewRequestFromUniveristyApi = (params = '') =>
  baseEndpoint('university/interview/findall') + params;

export const getInterviewRequestFromCompanyApi = () =>
  baseEndpoint('company/interview/findall');

export const getAcceptedInterviewApi = () =>
  baseEndpoint('company/accepted/interview/findall');

export const getInternshipOfferApi = () => baseEndpoint('offer/findall');
export const acceptInternshipOfferApi = (params = '') =>
  baseEndpoint('offer/accept/') + params;
export const denyInternshipOfferApi = (params = '') =>
  baseEndpoint('offer/deny/') + params;

export const acceptCompanyInterviewApi = (params = '') =>
  baseEndpoint('company/interview/accept/') + params;
// Accepted Internships
export const denyCompanyInterviewApi = (params = '') =>
  baseEndpoint('company/interview/deny/') + params;

export const acceptUniversityInterviewApi = (params = '') =>
  baseEndpoint('university/interview/accept/') + params;
// Accepted Internships
export const denyUniversityInterviewApi = (params = '') =>
  baseEndpoint('university/interview/deny/') + params;

export const getCompanyFeedbackApi = (studId = '') =>
  baseEndpoint('company/feedback/') + studId + '/list';

export const getRescheduledDataApi = (params = '') =>
  baseEndpoint('company/rescheduled/interview/findall') + params;

export const acceptRescheduleApi = (params = '') =>
  baseEndpoint('company/accepted/interview/reschedule/') + params;

export const denyRescheduleApi = (params = '') =>
  baseEndpoint('company/interview/deny/') + params;

// Accepted Internships

// export const getAcceptedInternshipsApi = () => {};

// Subcriptions Endpoints

//Job Endpoints
export const getJobsApi = () => baseEndpoint('/jobs/posted-jobs');
export const getAppliedJobsApi = () => baseEndpoint('/jobs/applied');
export const getJobDescriptionByIdApi = id =>
  baseEndpoint(`/jobs/job-description/${id}`);

//Interview Endpoints
export const getInterviewsApi = () => baseEndpoint('/interviews');
export const updateInterviewStatusApi = () =>
  baseEndpoint('/interviews/interview-status');

//Favorites Endpoints
export const toggleFavoriteJobApi = () => baseEndpoint('/favorites');
export const updateFavoriteSemiarApi = () => baseEndpoint('/favorite/seminars');

// Settings
export const getUserSettingsApi = user => accountBaseEndpoint('');
export const updateUserSettingsApi = (params = '') =>
  accountBaseEndpoint('/user/settings') + params;

//Notification Endpoint
export const getJobNotificationApi = user =>
  youngbrainBaseEndpoint(`/notification/${user}/job`);
export const getSeminarNotificationApi = user =>
  youngbrainBaseEndpoint(`/notification/${user}/seminar`);
export const deleteNotificationApi = user =>
  youngbrainBaseEndpoint(`/notification/clear/${user}`);

// Profile Endpoint
export const getProfileApi = (params = '') =>
  API_ENDPOINT + 'v1/student/profile/' + params;

export const updateProfileApi = (params = '') =>
  API_ENDPOINT + 'v1/student/profile/' + params;

//Seminar Endpoints
export const getSeminarInvitesApi = () => baseEndpoint('/seminars/invites');
export const getRegisteredSSeminarApi = () =>
  baseEndpoint('/seminars/registered');
export const getAttendedSeminarsApi = () => baseEndpoint('/seminars/attended');
export const getSeminarDetailsByIdApi = seminarId =>
  baseEndpoint(`/seminars/${seminarId}`);

// Auth Endpoints
export const loginUserApi = () => authBaseEndpoint('user/signin');
export const signupUserApi = () => authBaseEndpoint('user/signup');

export const getCertificateIssuingOrgListApi = () =>
  youngbrainBaseEndpoint('/certificate-organization-list');
export const getCertificationListApi = () =>
  youngbrainBaseEndpoint('/faculty-department-list');
export const getFacultiesApi = () =>
  youngbrainBaseEndpoint('/faculty-department-list');
export const getDepartmentListApi = () =>
  youngbrainBaseEndpoint('/faculty-department-list');
export const getSchoolUniversityListApi = () =>
  youngbrainBaseEndpoint('/school-university-list');

export const getStudentProfileApi = () =>
  youngbrainBaseEndpoint('/school-university-list');

export const forgotUserPasswordApi = (params = '') =>
  authBaseEndpoint('user/forgotPassword') + params;
export const resetUserPasswordApi = (params = '') =>
  authBaseEndpoint('user/resetPasswordLink/') + params;
